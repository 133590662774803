import axios from "axios";
import Cookies from "js-cookie";

const grafis = {
  namespaced: true,
  state: {
    assesment: null,
    statistic: null,
    s_year: null,
    s_gender: null,
    s_etnis: null,
    s_university: null,
    s_religion: null,
    errMsg: "",
    response: "",
  },
  getters: {},
  mutations: {
    ASSESSMENT(state, data) {
      state.assesment = data;
    },
    STATISTIC(state, data) {
      state.statistic = data;
    },
    S_GENDER(state, data) {
      state.s_gender = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // INFOGRAFIS ASSESSMENT
    assessmentInfografis(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env

        axios
          .get(`${env}/infografis/get-assesment?tahun=${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("ASSESSMENT", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    scholarInfografis(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env

        axios
          .get(`${env}/infografis/get-scholar?kategori=${data.category}&tahun_tf=${data.year}`, config)
          .then((res) => {
            let result = res.data.data;
            if (data.category == "jenis_kelamin") {
              context.commit("S_GENDER", result);
            }
            resolve(result);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    staticScholar(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env

        axios
          .get(`${env}/scholar/statistic/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("STATISTIC", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default grafis;
