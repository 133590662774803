import axios from "axios";
import Cookies from "js-cookie";

const exports = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
  },
  getters: {},
  mutations: {
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    exportReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/export/report-summary`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    exportScholar(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/export/scholar`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    exportAdmin(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let role = data.role;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/export/${role}`, data.status, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
  },
};

export default exports;
