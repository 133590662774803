import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/css/style.css";

import VueKonva from "vue-konva";
import "./plugins/Dayjs";

import VueSnip from "vue-snip";

import Vue2Editor from "vue2-editor";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import VueApexCharts from 'vue-apexcharts'
import VueObserveVisibility from "vue-observe-visibility";

Vue.use(VueObserveVisibility);

Vue.use(Chartkick.use(Chart));
Vue.use(Vue2Editor);
Vue.use(VueSnip);

Vue.use(VueKonva);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// Vue.use(Notifications);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
