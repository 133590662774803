<template>
  <div>
    <v-app-bar flat color="#EDF1F0" style="height: 10vh">
      <div
        class="d-flex justify-space-between align-center"
        style="width: 100%"
      >
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            @click.stop="toggleMini = !toggleMini"
          ></v-app-bar-nav-icon>
        </div>
        <div class="d-flex align-center">
          <!-- <v-btn
            v-if="role == 'ADMIN_ASSESSMENT' || role == 'SUPERADMIN'"
            depressed
            fab
            small
            color="green"
            class="mr-2"
            @click="toDownload"
            dark
          >
            <v-icon>mdi-progress-download</v-icon>
          </v-btn> -->
          <v-menu offset-y v-if="profile" nudge-bottom="10px">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="label_profile__ mr-2 d-flex align-center pa-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar size="34"
                  ><img
                    src="../assets/img/IconProfileDefault.png"
                    alt="pp"
                    style="object-fit: cover"
                /></v-avatar>
                <div class="px-3">
                  <h4 class="green--text lh">
                    {{ profile.data.account.nama_lengkap.split(" ")[0] }}
                  </h4>
                  <p class="ma-0 xmall-p">{{ label }}</p>
                </div>
              </div>
            </template>
            <v-list>
              <v-list-item
                :to="`/profile/${role.toLowerCase()}`"
                v-if="role !== 'SUPERADMIN'"
              >
                <v-list-item-title
                  ><div class="d-flex align-center">
                    <img
                      src="../assets/img/IconProfileDefault.png"
                      class="mr-3"
                      height="25px"
                      alt=""
                    />
                    Profile
                  </div></v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title
                  ><div class="d-flex align-center">
                    <img
                      src="../assets/img/logout.png"
                      class="mr-3"
                      height="25px"
                      alt=""
                    />
                    Logout
                  </div></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
    <SidebarSuper v-bind:toggleMini="toggleMini" v-if="role == 'SUPERADMIN'" />
    <SidebarAdmin
      v-bind:toggleMini="toggleMini"
      v-if="
        role === 'DATA_CONTROL' || role === 'TF' || role === 'ADMIN_ASSESSMENT'
      "
    />
    <SidebarScholar v-bind:toggleMini="toggleMini" v-if="role === 'SCHOLAR'" />
    <div>
      <router-view class="bg_default full_height__"></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SidebarAdmin from "./Sidebar/SidebarAdmin.vue";
import SidebarSuper from "./Sidebar/SidebarSuperAdmin.vue";
import Cookies from "js-cookie";
import SidebarScholar from "./Sidebar/SidebarScholar.vue";

export default {
  name: "Home",
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mini() {
      return !(this.smAndDown || this.toggleMini);
    },
    breakpoint() {
      return JSON.stringify(this.$vuetify.breakpoint);
    },
    ...mapState({
      profile: (state) => state.myProfile,
      role: (state) => state.role,
      dummy: (state) => state.dummy,
      id: (state) => state.id,
    }),
    label() {
      let tag = "";
      if (this.role) {
        if (this.role == "DATA_CONTROL") {
          tag = "DATA CONTROL";
        } else if (this.role == "TF") {
          tag = "ADMIN TF";
        } else if (this.role == "ADMIN_ASSESSMENT") {
          tag = "ADMIN ASSESSMENT";
        } else if (this.role == "SCHOLAR") {
          tag = "SCHOLAR";
        } else {
          tag = "SUPERADMIN";
        }
      }
      return tag;
    },
  },
  data() {
    return {
      toggleMini: false,
    };
  },

  components: {
    SidebarSuper,
    SidebarAdmin,
    SidebarScholar,
  },
  mounted() {
    // this.getPath();
    this.$store
      .dispatch("getProfile")
      .then(() => {
        let role = this.role.toLowerCase();
        if (role != "superadmin" && role != "scholar") {
          this.myAccess();
        }
      })
      .catch((err) => {
        console.log(err.status);
        if (err.code == 500) {
          console.log("error code ", err.code);
          Cookies.remove("token");
          this.$router.go("/login");
        }
      });
  },
  methods: {
    myAccess() {
      this.$store.dispatch("user/listMyAcces", this.id);
    },
    logout() {
      Cookies.remove("token");
      this.$router.go("/login");
    },
  },
};
</script>

<style scoped>
.label_profile__ {
  background: white;
  border-radius: 30px;
}
.xmall-p {
  font-size: x-small;
}
.lh {
  line-height: normal;
}
</style>
