import axios from "axios";
import Cookies from "js-cookie";

const auth = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
  },
  getters: {},
  mutations: {
    RESPONSE(state, data) {
      state.response = data;
    },
    RESET_RESPONSE(state) {
      state.response = "";
    },
    RESET_ERROR(state) {
      state.errMsg = "";
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    login(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/auth/login`, data)
          .then((res) => {
            let data = res.data;
            if (data.status == "success") {
              // set data to cookies
              Cookies.set("token", data.token, { expires: 7 });
              Cookies.set("id", data.data.id, { expires: 7 });
              Cookies.set("role", data.data.role, { expires: 7 });
              Cookies.set("status", data.data.status, { expires: 7 });
            }
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    logout(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        Cookies.remove("token");
        axios
          .post(`${env}/auth/logout`, data, config)
          .then((res) => {
            let data = res.data;
            Cookies.remove("role");
            Cookies.remove("id");
            localStorage.clear();
            context.commit("RESPONSE", data);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error);
          });
      });
    },
    requestResetPassword(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/auth/password/reset-request`, data)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    resetPassword(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/auth/password/reset`, data)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default auth;
