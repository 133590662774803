const point = {
  namespaced: true,
  state: {
    most: {
      d21: { x: 35, y: 68 },
      d20: { x: 35, y: 70 },
      d19: { x: 35, y: 78 },
      d18: { x: 35, y: 76 },
      d17: { x: 35, y: 80 },
      d16: { x: 35, y: 83 },
      d15: { x: 35, y: 86 },
      d14: { x: 35, y: 124 },
      d13: { x: 35, y: 134 },
      d12: { x: 35, y: 154 },
      d11: { x: 35, y: 164 },
      d10: { x: 35, y: 170 },
      d9: { x: 35, y: 187 },
      d8: { x: 35, y: 217 },
      d7: { x: 35, y: 225 },
      d6: { x: 35, y: 245 },
      d5: { x: 35, y: 260 },
      d4: { x: 35, y: 270 },
      d3: { x: 35, y: 300 },
      d2: { x: 35, y: 330 },
      d1: { x: 35, y: 355 },
      d0: { x: 35, y: 385 },

      i19: { x: 60, y: 68 },
      i18: { x: 60, y: 70 },
      i17: { x: 60, y: 71 },
      i16: { x: 60, y: 72 },
      i15: { x: 60, y: 73 },
      i14: { x: 60, y: 74 },
      i13: { x: 60, y: 75 },
      i12: { x: 60, y: 76 },
      i11: { x: 60, y: 77 },
      i10: { x: 60, y: 85 },
      i9: { x: 60, y: 92 },
      i8: { x: 60, y: 115 },
      i7: { x: 60, y: 124 },
      i6: { x: 60, y: 162 },
      i5: { x: 60, y: 171 },
      i4: { x: 60, y: 215 },
      i3: { x: 60, y: 260 },
      i2: { x: 60, y: 297 },
      i1: { x: 60, y: 340 },
      i0: { x: 60, y: 410 },

      s20: { x: 83, y: 68 },
      s19: { x: 83, y: 69 },
      s18: { x: 83, y: 73 },
      s17: { x: 83, y: 78 },
      s16: { x: 83, y: 80 },
      s15: { x: 83, y: 72 },
      s14: { x: 83, y: 86 },
      s13: { x: 83, y: 90 },
      s12: { x: 83, y: 118 },
      s11: { x: 83, y: 124 },
      s10: { x: 83, y: 133 },
      s9: { x: 83, y: 151 },
      s8: { x: 83, y: 166 },
      s7: { x: 83, y: 176 },
      s6: { x: 83, y: 212 },
      s5: { x: 83, y: 222 },
      s4: { x: 83, y: 250 },
      s3: { x: 83, y: 265 },
      s2: { x: 83, y: 325 },
      s1: { x: 83, y: 346 },
      s0: { x: 83, y: 372 },

      c17: { x: 109, y: 68 },
      c16: { x: 109, y: 70 },
      c15: { x: 109, y: 72 },
      c14: { x: 109, y: 74 },
      c13: { x: 109, y: 76 },
      c12: { x: 109, y: 80 },
      c11: { x: 109, y: 86 },
      c10: { x: 109, y: 95 },
      c9: { x: 109, y: 105 },
      c8: { x: 109, y: 114 },
      c7: { x: 109, y: 124 },
      c6: { x: 109, y: 170 },
      c5: { x: 109, y: 188 },
      c4: { x: 109, y: 217 },
      c3: { x: 109, y: 270 },
      c2: { x: 109, y: 327 },
      c1: { x: 109, y: 347 },
      c0: { x: 109, y: 385 },
    },
    least: {
      d0: { x: 35, y: 68 },
      d1: { x: 35, y: 82 },
      d2: { x: 35, y: 142 },
      d3: { x: 35, y: 180 },
      d4: { x: 35, y: 210 },
      d5: { x: 35, y: 225 },
      d6: { x: 35, y: 245 },
      d7: { x: 35, y: 262 },
      d8: { x: 35, y: 270 },
      d9: { x: 35, y: 300 },
      d10: { x: 38, y: 310 },
      d11: { x: 38, y: 319 },
      d12: { x: 38, y: 349 },
      d13: { x: 38, y: 363 },
      d14: { x: 38, y: 373 },
      d15: { x: 38, y: 385 },
      d16: { x: 38, y: 395 },
      d17: { x: 38, y: 398 },
      d18: { x: 38, y: 400 },
      d19: { x: 38, y: 405 },
      d20: { x: 38, y: 410 },

      i0: { x: 60, y: 76 },
      i1: { x: 60, y: 107 },
      i2: { x: 60, y: 152 },
      i3: { x: 60, y: 178 },
      i4: { x: 60, y: 223 },
      i5: { x: 60, y: 245 },
      i6: { x: 60, y: 291 },
      i7: { x: 60, y: 318 },
      i8: { x: 60, y: 344 },
      i9: { x: 60, y: 362 },
      i10: { x: 62, y: 383 },
      i11: { x: 62, y: 393 },
      i12: { x: 62, y: 400 },
      i13: { x: 62, y: 401 },
      i14: { x: 62, y: 401 },
      i15: { x: 62, y: 402 },
      i16: { x: 62, y: 403 },
      i17: { x: 62, y: 404 },
      i18: { x: 62, y: 405 },
      i19: { x: 62, y: 410 },

      s0: { x: 83, y: 68 },
      s1: { x: 83, y: 69 },
      s2: { x: 83, y: 106 },
      s3: { x: 83, y: 151 },
      s4: { x: 83, y: 177 },
      s5: { x: 83, y: 206 },
      s6: { x: 83, y: 223 },
      s7: { x: 83, y: 260 },
      s8: { x: 83, y: 292 },
      s9: { x: 83, y: 308 },
      s10: { x: 85, y: 345 },
      s11: { x: 85, y: 361 },
      s12: { x: 86, y: 384 },
      s13: { x: 86, y: 394 },
      s14: { x: 86, y: 395 },
      s15: { x: 86, y: 396 },
      s16: { x: 86, y: 402 },
      s17: { x: 86, y: 403 },
      s18: { x: 86, y: 404 },
      s19: { x: 86, y: 409 },

      c0: { x: 108, y: 68 },
      c1: { x: 108, y: 77 },
      c2: { x: 108, y: 115 },
      c3: { x: 108, y: 152 },
      c4: { x: 108, y: 176 },
      c5: { x: 108, y: 207 },
      c6: { x: 108, y: 225 },
      c7: { x: 108, y: 245 },
      c8: { x: 108, y: 262 },
      c9: { x: 108, y: 300 },
      c10: { x: 110, y: 317 },
      c11: { x: 110, y: 363 },
      c12: { x: 110, y: 371 },
      c13: { x: 110, y: 383 },
      c14: { x: 110, y: 392 },
      c15: { x: 110, y: 400 },
      c16: { x: 110, y: 403 },
      c17: { x: 110, y: 409 },
    },
    change: {
      d21: { x: 38, y: 68 },
      d20: { x: 38, y: 70 },
      d19: { x: 38, y: 71 },
      d18: { x: 38, y: 77 },
      d17: { x: 38, y: 80 },
      d16: { x: 38, y: 80 },
      d15: { x: 38, y: 85 },
      d14: { x: 38, y: 94 },
      d13: { x: 38, y: 115 },
      d12: { x: 38, y: 123 },
      d11: { x: 38, y: 126 },
      d10: { x: 38, y: 131 },
      d9: { x: 36, y: 153 },
      d8: { x: 36, y: 160 },
      d7: { x: 36, y: 179 },
      d6: { x: 36, y: 195 },
      d5: { x: 36, y: 208 },
      d4: { x: 36, y: 210 },
      d3: { x: 36, y: 216 },
      d2: { x: 36, y: 217 },
      d1: { x: 36, y: 224 },
      d0: { x: 36, y: 246 },
      "d-1": { x: 36, y: 248 },
      "d-2": { x: 37, y: 255 },
      "d-3": { x: 37, y: 263 },
      "d-4": { x: 37, y: 272 },
      "d-5": { x: 37, y: 290 },
      "d-6": { x: 37, y: 300 },
      "d-7": { x: 37, y: 309 },
      "d-8": { x: 37, y: 310 },
      "d-9": { x: 37, y: 318 },
      "d-10": { x: 40, y: 347 },
      "d-11": { x: 40, y: 365 },
      "d-12": { x: 40, y: 372 },
      "d-13": { x: 40, y: 380 },
      "d-14": { x: 40, y: 385 },
      "d-15": { x: 40, y: 390 },
      "d-16": { x: 40, y: 394 },
      "d-17": { x: 40, y: 396 },
      "d-18": { x: 40, y: 396 },
      "d-19": { x: 40, y: 397 },
      "d-20": { x: 40, y: 402 },

      i19: { x: 62, y: 68 },
      i18: { x: 62, y: 68 },
      i17: { x: 62, y: 69 },
      i16: { x: 62, y: 69 },
      i15: { x: 62, y: 69 },
      i14: { x: 62, y: 70 },
      i13: { x: 62, y: 70 },
      i12: { x: 62, y: 71 },
      i11: { x: 62, y: 71 },
      i10: { x: 62, y: 75 },
      i9: { x: 60, y: 76 },
      i8: { x: 60, y: 83 },
      i7: { x: 60, y: 115 },
      i6: { x: 60, y: 122 },
      i5: { x: 60, y: 140 },
      i4: { x: 60, y: 151 },
      i3: { x: 60, y: 169 },
      i2: { x: 60, y: 207 },
      i1: { x: 60, y: 215 },
      i0: { x: 60, y: 224 },
      "i-1": { x: 61, y: 246 },
      "i-2": { x: 61, y: 271 },
      "i-3": { x: 61, y: 280 },
      "i-4": { x: 61, y: 310 },
      "i-5": { x: 61, y: 318 },
      "i-6": { x: 61, y: 348 },
      "i-7": { x: 61, y: 355 },
      "i-8": { x: 61, y: 370 },
      "i-9": { x: 61, y: 385 },
      "i-10": { x: 64, y: 394 },
      "i-11": { x: 64, y: 395 },
      "i-12": { x: 64, y: 396 },
      "i-13": { x: 64, y: 397 },
      "i-14": { x: 64, y: 398 },
      "i-15": { x: 64, y: 398 },
      "i-16": { x: 64, y: 398 },
      "i-17": { x: 64, y: 398 },
      "i-18": { x: 64, y: 402 },

      s20: { x: 85, y: 68 },
      s19: { x: 85, y: 69 },
      s18: { x: 85, y: 69 },
      s17: { x: 85, y: 70 },
      s16: { x: 85, y: 70 },
      s15: { x: 85, y: 75 },
      s14: { x: 85, y: 76 },
      s13: { x: 85, y: 76 },
      s12: { x: 85, y: 77 },
      s11: { x: 85, y: 84 },
      s10: { x: 85, y: 92 },
      s9: { x: 84, y: 115 },
      s8: { x: 84, y: 123 },
      s7: { x: 84, y: 130 },
      s6: { x: 84, y: 140 },
      s5: { x: 84, y: 153 },
      s4: { x: 84, y: 160 },
      s3: { x: 84, y: 170 },
      s2: { x: 84, y: 187 },
      s1: { x: 84, y: 209 },
      s0: { x: 84, y: 215 },
      "s-1": { x: 86, y: 246 },
      "s-2": { x: 86, y: 255 },
      "s-3": { x: 86, y: 263 },
      "s-4": { x: 86, y: 272 },
      "s-5": { x: 86, y: 280 },
      "s-6": { x: 86, y: 310 },
      "s-7": { x: 86, y: 318 },
      "s-8": { x: 86, y: 347 },
      "s-9": { x: 86, y: 246 },
      "s-10": { x: 89, y: 385 },
      "s-11": { x: 89, y: 490 },
      "s-12": { x: 89, y: 392 },
      "s-13": { x: 89, y: 393 },
      "s-14": { x: 89, y: 395 },
      "s-15": { x: 89, y: 400 },
      "s-16": { x: 89, y: 305 },
      "s-17": { x: 89, y: 405 },
      "s-18": { x: 89, y: 410 },

      c17: { x: 111, y: 68 },
      c16: { x: 111, y: 70 },
      c15: { x: 111, y: 70 },
      c14: { x: 111, y: 70 },
      c13: { x: 111, y: 70 },
      c12: { x: 111, y: 70 },
      c11: { x: 111, y: 70 },
      c10: { x: 111, y: 75 },
      c9: { x: 109, y: 80 },
      c8: { x: 109, y: 80 },
      c7: { x: 109, y: 80 },
      c6: { x: 109, y: 85 },
      c5: { x: 109, y: 93 },
      c4: { x: 109, y: 115 },
      c3: { x: 109, y: 139 },
      c2: { x: 109, y: 153 },
      c1: { x: 109, y: 170 },
      c0: { x: 109, y: 210 },
      "c-1": { x: 110, y: 217 },
      "c-2": { x: 110, y: 224 },
      "c-3": { x: 110, y: 247 },
      "c-4": { x: 110, y: 254 },
      "c-5": { x: 110, y: 302 },
      "c-6": { x: 110, y: 310 },
      "c-7": { x: 110, y: 317 },
      "c-8": { x: 110, y: 347 },
      "c-9": { x: 110, y: 356 },
      "c-10": { x: 112, y: 371 },
      "c-11": { x: 112, y: 217 },
      "c-12": { x: 112, y: 217 },
      "c-13": { x: 112, y: 385 },
      "c-14": { x: 112, y: 388 },
      "c-15": { x: 112, y: 392 },
      "c-16": { x: 112, y: 395 },
      "c-17": { x: 112, y: 395 },
      "c-18": { x: 112, y: 395 },
      "c-19": { x: 112, y: 400 },
      "c-20": { x: 112, y: 405 },
      "c-21": { x: 112, y: 405 },
      "c-22": { x: 112, y: 410 },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default point;
