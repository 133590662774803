import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";

import auth from "./module/auth";
import user from "./module/user";
import report from "./module/report";
import point from "./module/point";
import log from "./module/log";
import comment from "./module/comment";
import grafis from "./module/infografis";
import exports from "./module/export";
import trash from "./module/trash";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: Cookies.get("token") || null, //token
    env: process.env.VUE_APP_ENV, //env
    role: Cookies.get("role") || null, //role
    id: Cookies.get("id"),
    status: Cookies.get("status"),
    dummy: "https://i.postimg.cc/B6pWdFmM/jihyo2.jpg",
    myProfile: null,
    errMsg: null,
  },
  getters: {
    getUser(state) {
      return state.token !== null && state.token !== undefined;
    },
  },
  mutations: {
    GET_PROFILE(state, data) {
      state.myProfile = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // LIST USER
    getProfile(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .get(`${env}/auth/my-profile`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_PROFILE", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIST USER
    getColumn(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .get(`${env}/header/${data}`, config)
          .then((res) => {
            let data = res.data;
            // context.commit("GET_COLUMN", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
  modules: {
    auth,
    user,
    report,
    point,
    log,
    comment,
    grafis,
    exports,
    trash,
  },
});
