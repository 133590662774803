import axios from "axios";
import Cookies from "js-cookie";

const trash = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    trash: null,
  },
  getters: {},
  mutations: {
    TRASH(state, data) {
      state.trash = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    listTrash(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let url = "";
        let type = data.type;
        let role = data.role ? data.role : '';
        if (type == "report") {
          url = `report/trash?include=dataScholar&sort=-deleted_at`;
        } else {
        url = `trash-user?sort=-deleted_at&role=${role}`;
        }
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/${url}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("TRASH", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    moveToTrash(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        let body = data.body;
        let type = data.type;
        let url = "";
        if (type == "report") {
          url = `${type}/trash`;
        } else {
          url = `trash-user`;
        }
        axios
          .post(`${env}/${url}`, body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    restoreItem(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        let body = data.body;
        let type = data.type;
        let url = "";
        if (type == "report") {
          url = `report/restore`;
        } else {
          url = `restore-user`;
        }
        axios
          .post(`${env}/${url}`, body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
  },
};

export default trash;
