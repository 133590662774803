import axios from "axios";
import Cookies from "js-cookie";
import "../../plugins/Dayjs";

const log = {
  namespaced: true,
  state: {
    log: null,
    errMsg: "",
    response: "",
    chart: {},
  },
  getters: {},
  mutations: {
    LOG(state, data) {
      state.log = data.data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // LIST REPORT
    listLog(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        let page = data.page ? data.page : "";
        let limit = data.limit ? data.limit : "";
        axios
          .get(`${env}/log?page[number]=${page}&page[size]=${limit}&sort=-created_at`, config)
          .then((res) => {
            let data = res.data;
            context.commit("LOG", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default log;
