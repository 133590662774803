<template>
  <v-navigation-drawer
    color="#0E1614"
    dark
    app
    floating
    :permanent="sidebarMenu"
    :mini-variant.sync="mini"
    :mini-variant-width="100"
    style="padding: 0"
  >
    <v-list-item
      class="bg-head"
      :style="toggleMini ? `height: 65px` : `height: 150px`"
    >
      <div class="d-flex justify-center full_width__">
        <img
          src="../../assets/img/sidebarLogo.png"
          :height="toggleMini ? `65px` : `150px`"
          alt=""
        />
      </div>
    </v-list-item>
    <v-divider></v-divider>
    <v-list class="mb-10" dense v-if="access">
      <v-list-item active-class="white--text" link to="/">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="white--text"
        link
        to="/list/report"
        v-if="role !== 'ADMIN'"
      >
        <v-list-item-icon>
          <v-icon>mdi-text-box-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Report Summary</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="white--text"
        link
        to="/generate/report"
        v-if="role == 'ADMIN_ASSESSMENT'"
      >
        <v-list-item-icon>
          <v-icon>mdi-progress-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Download Report</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="white--text"
        link
        to="/report/trash"
        v-if="role == 'ADMIN_ASSESSMENT'"
      >
        <v-list-item-icon>
          <v-icon>mdi-delete-empty</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Trash</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="white--text"
        link
        to="/list/user/tf"
        v-if="access.tf.length"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-hard-hat</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Admin TF</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="white--text"
        link
        to="/list/user/admin_assessment"
        v-if="access.admin_assessment.length"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Admin Assessment</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="white--text"
        link
        to="/list/user/scholar"
        v-if="access.scholar.length"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-child-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Scholar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item
        active-class="white--text"
        link
        to="/user/trash"
        v-if="role == 'DATA_CONTROL'"
      >
        <v-list-item-icon>
          <v-icon>mdi-delete-empty</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Trash</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-group
        no-action
        prepend-icon="mdi-delete-empty"
        active-class="white--text"
        v-if="role == 'DATA_CONTROL'"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Trash</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item active-class="white--text" link to="/user/trash/tf">
          <v-list-item-content>
            <v-list-item-title>TF Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="white--text"
          link
          to="/user/trash/admin_assessment"
        >
          <v-list-item-content>
            <v-list-item-title>Assessment Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="white--text" link to="/user/trash/scholar">
          <v-list-item-content>
            <v-list-item-title>Scholar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <!-- <div class="footer_">
      <p :class="toggleMini ? `ma-0 text-small text-center` : `ma-0 `">
        {{ label }}
      </p>
    </div> -->
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  props: ["toggleMini"],
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    ...mapState({
      access: (state) => state.user.my_access,
      role: (state) => state.role,
    }),
    label() {
      let tag = "";
      if (this.role) {
        if (this.role == "DATA_CONTROL") {
          tag = "DATA CONTROL";
        } else if (this.role == "TF") {
          tag = "ADMIN TF";
        } else if (this.role == "ADMIN_ASSESSMENT") {
          tag = "ADMIN ASSESSMENT";
        } else if (this.role == "SCHOLAR") {
          tag = "SCHOLAR";
        }
      }
      return tag;
    },
  },
  mounted() {},
  data() {
    return {
      sidebarMenu: true,
    };
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style scoped>
.bg-head {
  background: #006341;
}
.bg {
  background: #0e1614;
}
._avatar {
  object-fit: cover;
}
.logo-wumbo {
  height: 70px;
  width: 170px;
}
.logo-mini {
  height: 40px;
  width: 90px;
}
.logo-wumbo2 {
  height: 50px;
  width: 150px;
}
.logo-mini2 {
  height: 40px;
  width: 100px;
}
.footer_ {
  position: absolute;
  bottom: 0;
  color: white;
  padding: 10px;
  background: #006341;
  width: 100%;
}
.text-small {
  font-size: small;
}
</style>
