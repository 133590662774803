<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import "./assets/js/detect.min.js";

export default {
  name: "App",
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scrollbar-shadow-color: none;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}
</style>
