import axios from "axios";
import Cookies from "js-cookie";

const comment = {
  namespaced: true,
  state: {
    list_comment: null,
    list_thread: null,
    errMsg: "",
    response: "",
    topThread: null,
    centerThread: null,
    bottomThread: null,
  },
  getters: {},
  mutations: {
    COMMENT(state, data) {
      state.list_comment = data.data;
    },
    THREAD(state, data) {
      let result = data.data.data
      state.list_thread = result;
      state.topThread = result.find((i) => { return i.tipe == 'top' })
      state.centerThread = result.find((i) => { return i.tipe == 'center' })
      state.bottomThread = result.find((i) => { return i.tipe == 'bottom' })
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // LIST COMMENT
    listThread(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env

        axios
          .get(`${env}/comment/thread/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("THREAD", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIST COMMENT
    listComment(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env

        axios
          .get(`${env}/comment/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("COMMENT", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // CREATE THREAD
    createThread(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        
        axios
          .post(`${env}/comment/thread`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // CREATE COMMENT
    comment(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        let body = data.body;
        let params = data.params ? data.params : 0
        axios
          .post(`${env}/comment?parent_id=${params}`, body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // UPDATE COMMENT
    updateComment(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/comment/update`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // DELETE COMENT
    deleteComment(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/comment/delete`, data, config)
          .then((res) => {
            let data = res.data;
            console.log(data);
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default comment;
