import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Dashboard/mainDashboard.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/list/user/:role",
        name: "List User",
        component: () => import("../views/UserManagement/List/ListUser.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/create/user/:role",
        name: "Create User",
        component: () => import("../views/UserManagement/Create/createUser.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/update/user/:id/:role",
        name: "update User",
        component: () => import("../views/UserManagement/Update/updateUser.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/list/report",
        name: "List Report",
        component: () => import("../views/Report/List/ListReport.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/create/report/:id/:periode",
        name: "create Report",
        component: () => import("../views/Report/Create/createReport.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/update/report/:id/:periode",
        name: "update Report",
        component: () => import("../views/Report/Update/updateReport.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/report/:periode/:id",
        name: "viewReport",
        component: () => import("../views/Report/view/viewReport.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/batch/report/:periode",
        name: "batch Report",
        component: () => import("../components/Report/Create/dropCreateReport.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/generate/report",
        name: "download",
        component: () => import("../views/Report/view/viewDownloadBatch.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/batch/user/scholar",
        name: "batch scholar",
        component: () => import("../components/UserManagement/Create/dropCreateScholar.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/log",
        name: "log",
        component: () => import("../views/Log/log.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/report/trash",
        name: "trash",
        component: () => import("../views/Report/Delete/trashReport.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/user/trash/:role",
        name: "supertrash",
        component: () => import("../components/UserManagement/Delete/trashUser.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/profile/:role",
        name: "profile-role",
        component: () => import("../views/Profile/profile.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/load",
        name: "logs",
        component: () => import("../components/etc/loading-component/loadingLG.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/recap/:id",
        name: "recap",
        component: () => import("../views/Dashboard/progressTeladan.vue"),
        meta: { requiresAuth: true },
      },
      
      // {
      //   path: "*",
      //   name: "pageNotFound",
      //   component: () => import("../views/ETC/pageNotFound.vue"),
      // },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/login.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/Auth/ForgotPassword/sendRequest.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/sent/:email",
    name: "email-sent",
    component: () => import("../views/Auth/ForgotPassword/emailSent.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/reset/:token/:email",
    name: "reset-password",
    component: () => import("../views/Auth/ForgotPassword/resetPwd.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: () => import("../views/ETC/accessDenied.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/tes",
    name: "AccessDenied",
    component: () => import("../views/ETC/check.vue"),
    meta: { requiresVisitor: true },
  },

  // {
  //   path: "*",
  //   name: "pageNotFound",
  //   component: () => import("../views/ETC/pageNotFound.vue"),
  //   meta: { requiresVisitor: true },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getUser) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.getUser) {
      if (store.state.status == 1) {
        next({
          path: "/",
        });
      } else {
        next({
          path: "/deactive",
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
