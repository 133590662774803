import axios from "axios";
import Cookies from "js-cookie";

const report = {
  namespaced: true,
  state: {
    list_report: null,
    form_report: null,
    recap: null,
    score: null,
    errMsg: "",
    response: "",
    yearFilter: null,
    phaseFilter: null,
    queue: null,
    cachedColumn: null,
  },
  getters: {},
  mutations: {
    CACHE_COLUMN(state, data) {
      state.cachedColumn = data;
    },
    MUTATE_YEAR(state, data) {
      state.yearFilter = data;
    },
    MUTATE_PHASE(state, data) {
      state.phaseFilter = data;
    },
    REPORT(state, data) {
      state.list_report = data;
    },
    RECAP(state, data) {
      state.recap = data.data;
    },
    SCORE(state, data) {
      state.score = data;
    },
    FORM(state, data) {
      state.form_report = data;
    },
    GET_LIST_DOWNLOAD(state, data) {
      state.queue = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // LIST REPORT
    listReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        // let id = data.id ? data.id : "";
        let find = data.find ? data.find : "";
        let periode = data.periode != null ? data.periode : "";
        let year = data.year ? data.year : "";
        let page = data.page ? data.page : "";
        let limit = data.limit ? data.limit : "";
        axios
          .get(`${env}/report/summary?include=dataScholar,dataUpdated&tahun_tf=${year}&filter[search]=${find}&filter[periode]=${periode}&page[number]=${page}&page[size]=${limit}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("REPORT", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // CREATE REPORT
    createReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/report/summary`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // UPDATE REPORTt
    updateReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/report/summary/update`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // GET FORM REPORT
    getForm(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .get(`${env}/report/summary-form/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("FORM", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // GET FORM REPORT
    viewScore(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .get(`${env}/report/get/${data.id}/${data.periode}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("SCORE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // GET RECAP
    viewRecap(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .post(`${env}/histori/report`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RECAP", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // CREATE REPORT
    importReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/import/scholar/report-summary`, data.file, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    downloadReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/download/report-summary`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            // console.log(error.response.data.message);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    downloadBatchReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/download/report-summary/batch`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            // console.log(error.response.data.message);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    getListDownload(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/download/report-summary/batch/list?page=${data.page}&per_page=${data.limit}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_LIST_DOWNLOAD", data);
            resolve(data);
          })
          .catch((error) => {
            // console.log(error.response.data.message);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    delQueue(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/download/report-summary/batch/delete`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            // console.log(error.response.data.message);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
    deleteReport(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/report/force-delete`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },
  },
};

export default report;
