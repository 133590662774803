<template>
  <v-navigation-drawer
    color="#0E1614"
    dark
    app
    floating
    :permanent="sidebarMenu"
    :mini-variant.sync="mini"
    :mini-variant-width="100"
    style="padding: 0"
  >
    <v-list-item
      class="bg-head"
      :style="toggleMini ? `height: 65px` : `height: 150px`"
    >
      <div class="d-flex justify-center full_width__">
        <img
          src="../../assets/img/sidebarLogo.png"
          :height="toggleMini ? `65px` : `150px`"
          alt=""
        />
      </div>
    </v-list-item>
    <v-divider></v-divider>
    <v-list class="mb-10" dense v-if="profile">
      <v-list-item active-class="white--text" link to="/">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        no-action
        prepend-icon="mdi-text-box-multiple"
        active-class="white--text"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Report</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          active-class="white--text"
          link
          :to="`/report/baseline/${profile.data.profile.id}`"
        >
          <v-list-item-content>
            <v-list-item-title>Baseline</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="white--text"
          link
          :to="`/report/midline/${profile.data.profile.id}`"
        >
          <v-list-item-content>
            <v-list-item-title>Midline</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="white--text"
          link
          :to="`/report/endline/${profile.data.profile.id}`"
        >
          <v-list-item-content>
            <v-list-item-title>Endline</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  props: ["toggleMini"],
  computed: {
    ...mapState({
      id: (state) => state.id,
      profile: (state) => state.myProfile,
    }),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  data() {
    return {
      sidebarMenu: true,
    };
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style scoped>
.bg-head {
  background: #006341;
}
.bg {
  background: #0e1614;
}
._avatar {
  object-fit: cover;
}
.logo-wumbo {
  height: 70px;
  width: 170px;
}
.logo-mini {
  height: 40px;
  width: 90px;
}
.logo-wumbo2 {
  height: 50px;
  width: 150px;
}
.logo-mini2 {
  height: 40px;
  width: 100px;
}
.footer_ {
  position: absolute;
  bottom: 0;
  color: white;
  padding: 10px;
  background: #006341;
  width: 100%;
}
.text-small {
  font-size: small;
}
</style>
