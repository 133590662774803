import axios from "axios";
import Cookies from "js-cookie";

const user = {
  namespaced: true,
  state: {
    list_user: null,
    errMsg: "",
    response: "",
    my_access: null,
    userCounter: null,
    yearFilter: null,
    phaseFilter: 1,
  },
  getters: {},
  mutations: {
    MUTATE_YEAR(state, data) {
      state.yearFilter = data;
    },
    MUTATE_PHASE(state, data) {
      state.phaseFilter = data;
    },
    USER(state, data) {
      state.list_user = data;
    },
    COUNTER_USER(state, data) {
      state.userCounter = data.data;
    },
    LIST_ACCESS_USER(state, data) {
      state.list_access_user = data.data;
    },
    LIST_MY_ACCESS(state, data) {
      state.my_access = data.data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // LIST USER
    listUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        let role = data.role; //define role
        let id = data.id ? data.id : "";
        let find = data.find ? data.find : "";
        let status = data.status != null ? data.status : "";
        let page = data.page ? data.page : "";
        let year = data.year ? data.year : "";
        let limit = data.limit ? data.limit : "";
        let include = data.include;
        console.log(data);
        axios
          .get(
            `${env}/user/list?include=${include}&filter[role]=${role}&filter[id]=${id}&filter[users]=${find}&filter[status]=${status}&tahun_tf=${year}&page[number]=${page}&page[size]=${limit}`,
            config
          )
          .then((res) => {
            let data = res.data;
            context.commit("USER", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // CREATE USER
    createUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        let role = data.role;
        let body = data.body;
        axios
          .post(`${env}/user/${role}`, body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    createUserBatch(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;

        axios
          .post(`${env}/import/scholar`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // UPDATE AKUN
    updateAccount(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/user/akun/update`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // UPDATE USER
    updateUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        let role = data.role;
        let body = data.body;
        axios
          .post(`${env}/user/profile/${role}`, body, config)
          .then((res) => {
            let data = res.data;
            console.log(data);
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // DELETE USER
    deleteUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/user/akun/delete`, data, config)
          .then((res) => {
            let data = res.data;
            console.log(data);
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    listMyAcces(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .get(`${env}/user/permission/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("LIST_MY_ACCESS", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    listUserAcces(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .get(`${env}/user/permission/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("LIST_ACCESS_USER", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    // ASSIGN ACCESS USER
    assignAccess(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/user/permission/assign`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    countUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .get(`${env}/user/count/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("COUNTER_USER", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    permanentDeleteBulk(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/force-delete-user`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            reject(error);
          });
      });
    },

    dowloadTemplate(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // header token
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV; // endpoint from env
        axios
          .post(`${env}/import/scholar/template`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default user;
